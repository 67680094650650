import { get } from 'lodash'
import { createSelector } from 'reselect'
import {
	outboundFlightFromOfferSelector,
	outboundFlightFromProductSelector,
	inboundFlightFromOfferSelector,
	inboundFlightFromProductSelector,
	flightConsistFromOfferSelector,
	flightConsistFromProductSelector,
	airportsFromOfferSelector,
	airportsFromProductSelector,
	flightFaresFromOfferSelector,
	flightFaresFromProductSelector,
	getAirlines,
	serviceByFareIdSelector,
	sortedSegmentsByFareIdSelector,
} from '@store/state/domainData/selectors'
import { byCurrentSearchQuery } from '@store/state/appState/selectors'
import { ternarySelectorCreator } from './helper.js'


export const fareBySegmentIdResult = (fares, segmentsByFareId) => (
	Object.keys(fares).reduce((acc, fareId) => {
		const segments = segmentsByFareId[fareId]

		const fareBySegmentId = segments.reduce((prev, segment) => ({
			...prev,
			[segment.id]: fares[fareId],
		}), {})

		return { ...acc, ...fareBySegmentId }
	}, {})
)

export const fareBySegmentIdSelector = createSelector(
	ternarySelectorCreator(flightFaresFromOfferSelector, flightFaresFromProductSelector),
	byCurrentSearchQuery(sortedSegmentsByFareIdSelector),
	fareBySegmentIdResult
)


const DEFAULT_CHECKED_BAGGAGE_WEIGHT = 20
const DEFAULT_HAND_BAGGAGE_WEIGHT = 5

function shapeBaggage (baggage, kind = 'checked') {
	if (!baggage || !baggage.included) {
		return []
	}
	else {
		const defaultWeight = kind === 'checked' ? DEFAULT_CHECKED_BAGGAGE_WEIGHT : DEFAULT_HAND_BAGGAGE_WEIGHT

		return Array(baggage.included).fill(baggage.weight || defaultWeight)
	}
}

const getShapedBaggage = ({ checked, hand, checked_unavail: checkedUnavail }) => ({
	hasCheckedOnFlight: !checkedUnavail,
	checked: shapeBaggage(checked, 'checked'),
	unchecked: shapeBaggage(hand, 'hand'),
})

const toCustomSegment = (airlines, airports, consist, fareBySegmentId, serviceByFareId) => (segment = {}) => {
	const {
		airline: airlineId,
		arrive = {},
		depart = {},
	} = segment
	const fare = fareBySegmentId[segment.id]
	const service = serviceByFareId[fare.id]

	const baggage = get(fare, 'details.baggage', {})
	const airline = airlines[airlineId] || {}
	const arriveAirport = airports[arrive.airport] || {}
	const departAirport = airports[depart.airport] || {}

	const shapedBaggage = getShapedBaggage(baggage)
	const cabinClass = get(fare, 'details.seats_class', '')
	const type = get(service, 'subtype', '').split(':')[1]

	return {
		...segment,
		fareId: fare.id,
		airline,
		arrive: { ...segment.arrive, airport: arriveAirport },
		depart: { ...segment.depart, airport: departAirport },
		consist,
		baggage: shapedBaggage,
		'cabin_class': cabinClass,
		type,
	}
}

const selector = (...args) => {
	const [
		outboundFlight,
		inboundFlight,
		airlines,
		airports,
		consist,
		fareBySegmentId,
		serviceByFareId,
	] = args

	const toCustomArgs = [ airlines, airports, consist, fareBySegmentId, serviceByFareId ]

	const hasInboundFlight = inboundFlight.length > 0

	const customOutboundFlight = {
		segments: outboundFlight.map(toCustomSegment(...toCustomArgs)),
	}

	const flights = [ customOutboundFlight ]

	const customInboundFlight = {
		segments: inboundFlight.map(toCustomSegment(...toCustomArgs)),
	}

	if (hasInboundFlight) flights.push(customInboundFlight)

	return flights
}

export default createSelector(
	ternarySelectorCreator(outboundFlightFromOfferSelector, outboundFlightFromProductSelector),
	ternarySelectorCreator(inboundFlightFromOfferSelector, inboundFlightFromProductSelector),
	byCurrentSearchQuery(getAirlines),
	ternarySelectorCreator(airportsFromOfferSelector, airportsFromProductSelector),
	ternarySelectorCreator(flightConsistFromOfferSelector, flightConsistFromProductSelector),
	fareBySegmentIdSelector,
	byCurrentSearchQuery(serviceByFareIdSelector),
	selector
)

